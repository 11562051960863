import { ClientOptions, GameTypeOptions, PoolTypeOptions, SportTypeOptions } from "../../../../common/game-enums"
import { PoolSettingsOptions, SeasonAttributes, SeasonTypeOptions, SegmentAttributes, SubsectionTypes } from "../../../../common/pool-settings"

export { SeasonAttributes, SegmentAttributes, SubsectionTypes }

export const localStorageKey = "_GameInstanceNewForm"
const SportTypes = SportTypeOptions

const Clients = ClientOptions
const PoolTypes = PoolTypeOptions
const GameTypes = GameTypeOptions
const SeasonTypes = SeasonTypeOptions
export { SportTypes, Clients, PoolTypes, GameTypes, SeasonTypes }

export const OneDay = 1000 * 60 * 60 * 24
// ['BEFORE_START_OF_EACH_GAME', 'BEFORE_START_OF_DAYS_FIRST_GAME', 'BEFORE_START_OF_PERIODS_FIRST_GAME']
export const PicksDeadlineTypes = PoolSettingsOptions.picksDeadlineType
// ['STRAIGHT_UP', 'CBS_SPREAD', 'CUSTOM_SPREAD']
export const SpreadTypes = PoolSettingsOptions.spreadType
// ['NONE', 'STANDARD', 'VEGAS', 'ROUND', 'CUSTOM']
export const GameWeightTypes = PoolSettingsOptions.gameWeightType
export const RoundBonusTypes = PoolSettingsOptions.roundBonusType
export const TiebreakerTypes = PoolSettingsOptions.mainTiebreaker
// export const ForgotPickFallbackTypes = PoolSettingsOptions.forgotPickFallback;
export const GamesPerPeriodTypes = PoolSettingsOptions.gamesPerPeriod
// export const BracketMarginOfVictoryBonusTypes = PoolSettingsOptions.bracketMarginOfVictoryBonus;
export const MaxGamesPerPlayoffPeriod = {
  NFL: [0, 1, 2, 3],
  NCAAB: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
}

export const ITEM_HEIGHT = 48
export const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
