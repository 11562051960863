/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeriodLengthEnumType } from "../__generated__/globalTypes"
import { TSeasonType, TSeasonYear, TSportType, IRecordsMapping, ITournamentMapping, IRecordsUnion } from "./db-typings"
import {
  GameTypeEnum,
  PeriodLengthEnum,
  SubsectionEnum,
  TiebreakerQuestionKeyEnum,
  TiebreakerTypeEnum,
  ENUM_NFL,
  ENUM_NCAAB,
  ENUM_NCAAW,
  ENUM_NCAAF,
  ENUM_PICKEM,
  ENUM_REGULAR,
  ENUM_PARLAY,
  ENUM_PRE,
  ENUM_POST,
  ENUM_UNDERDOG,
} from "./enums"
import { periodExtraDisabledEventTitlesKey, periodExtraTiebreakerQuestionTitleKey, getExtraColumn, periodOverrideStartsAtAttr } from "./extra-columns"
import { GameSportType } from "./game-enums"
import { emptyArray, filterNulls, toDbDate, unique } from "./misc-utils"
import {
  TiebreakerTypesForCombinedWithCustomQuestions,
  TiebreakerTypesForCustomOnlyQuestions,
  TiebreakerTypesWithQuestions,
  ValidTiebreakerQuestions,
} from "./pool-settings"
import { sortByStartsAt } from "./sorters"
import {
  buildNcaaTournamentMatchups,
  championshipGameName,
  getMatchupSportYearFor,
  NcaaTournament,
  NcaawTournament,
  Tournaments,
} from "./tournament-groups"
export const OneDay = 1000 * 60 * 60 * 24

export { toDbDate }

const NflSportType = ENUM_NFL
const NcaabSportType = ENUM_NCAAB
const NcaawSportType = ENUM_NCAAW
const NcaafSportType = ENUM_NCAAF

const NflPlayoffPeriodDescriptions = ["Wild Card", "Divisional", "Conference", "Championship"]

const dupObject = <T>(o: T): T => Object.assign({}, o)
const mapToTiebreakerQuestion = (tiebreakerType: TiebreakerQuestionKeyEnum | TiebreakerTypeEnum) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  dupObject(ValidTiebreakerQuestions.find((tq) => tq.key === tiebreakerType)!)

const filterToOnlyQuestionTypes = (tiebreakerType) => filterNulls(tiebreakerType) && TiebreakerTypesWithQuestions.includes(tiebreakerType)

export const buildTiebreakerQuestions = ({
  period,
  isLastPeriod,
  sportTypes,
  seasonType,
  year,
  mainTiebreaker,
  secondaryTiebreaker,
  thirdTiebreaker,
  fourthTiebreaker,
  subsection,
  isFirstPeriod,
}: {
  period: any
  isLastPeriod: boolean
  sportTypes: TSportType[]
  seasonType: TSeasonType
  year: TSeasonYear
  mainTiebreaker: TiebreakerTypeEnum
  secondaryTiebreaker?: TiebreakerTypeEnum
  thirdTiebreaker?: TiebreakerTypeEnum
  fourthTiebreaker?: TiebreakerTypeEnum
  subsection?: SubsectionEnum
  isFirstPeriod?: boolean
}) => {
  const types = [mainTiebreaker, secondaryTiebreaker, thirdTiebreaker, fourthTiebreaker].filter(filterToOnlyQuestionTypes) as TiebreakerTypeEnum[]
  const customTypes = types.filter((t) => t === TiebreakerTypeEnum.CUSTOM)
  const nonCustomTypes = types.filter((t) => t !== TiebreakerTypeEnum.CUSTOM)

  let customTiebreakerQuestionsInfo: TiebreakerQuestionKeyEnum[] = []
  if (thirdTiebreaker && fourthTiebreaker && thirdTiebreaker === "CUSTOM" && fourthTiebreaker === "CUSTOM") {
    customTiebreakerQuestionsInfo = [
      TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE,
      TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE,
      TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2,
      TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2,
    ]
  } else {
    customTiebreakerQuestionsInfo = customTypes.length
      ? nonCustomTypes.length
        ? TiebreakerTypesForCombinedWithCustomQuestions
        : TiebreakerTypesForCustomOnlyQuestions
      : emptyArray
  }
  const customTiebreakerQuestions = customTiebreakerQuestionsInfo.filter((q) => {
    if (sportTypes.includes(ENUM_NCAAF)) {
      // CFB does not have accurate stats
      return q !== TiebreakerQuestionKeyEnum.TOTAL_OFFENSIVE_YARDS
    } else if (sportTypes.includes(ENUM_NFL) && seasonType === ENUM_POST && year >= 2020) {
      // NFL Playoff PickEm 2020 want home team score + away team score
      return q !== TiebreakerQuestionKeyEnum.TOTAL_OFFENSIVE_YARDS
    } else {
      return true
    }
  })
  const tiebreakerQuestions = nonCustomTypes.map(mapToTiebreakerQuestion).concat(customTiebreakerQuestions.map(mapToTiebreakerQuestion))
  // validate questions
  if (types.includes(TiebreakerTypeEnum.TOURNAMENT_WINNER) && seasonType !== ENUM_POST) {
    throw new Error(`TOURNAMENT_WINNER only allowed in POST season`)
  }
  if (subsection?.includes(SubsectionEnum.NcaaTournament) || subsection?.includes(SubsectionEnum.NcaawTournament)) {
    if (!isFirstPeriod) {
      return null
    }
  } else if (seasonType === ENUM_POST && !isLastPeriod) {
    return null
  }
  if (tiebreakerQuestions.length) {
    return tiebreakerQuestions
  }
  return null
}

export function buildOPMDefaultSegmentTimesFor(sportType: TSportType, season: TSeasonType, productYear: TSeasonYear) {
  const year = productYear
  const isNCAAF = sportType === "NCAAF"
  const isNFL = sportType === "NFL"
  const isRegularSeason = season === "regular"
  const isPostSeason = season === "post"
  const nflRegularSeasonStart = `${year}-09-06T08:00`
  const nflRegularSeasonEnd = `${year + 1}-01-10T08:00`
  const nflPostSeasonEnd = `${year + 1}-02-15T08:00`
  const ncaafRegularSeasonStart = `${year}-08-28T08:00`
  const ncaafRegularSeasonEnd = `${year}-12-06T08:00`
  if (isRegularSeason) {
    if (isNFL) {
      return {
        segmentStartsAt: nflRegularSeasonStart,
        segmentPreOpenAt: `${year}-06-06T08:00`,
        segmentEndsAt: nflRegularSeasonEnd,
      }
    } else if (isNCAAF) {
      return {
        segmentStartsAt: ncaafRegularSeasonStart,
        segmentPreOpenAt: `${year}-06-03T08:48`,
        segmentEndsAt: ncaafRegularSeasonEnd,
      }
    } else {
      throw new Error(`Unsupported sportType ${sportType} for regular season OPM`)
    }
  } else if (isPostSeason) {
    if (isNFL) {
      return {
        segmentStartsAt: nflRegularSeasonEnd,
        segmentPreOpenAt: `${year}-12-17T08:00`,
        segmentEndsAt: nflPostSeasonEnd,
      }
    } else {
      throw new Error(`${sportType} is not supported for post season OPM`)
    }
  } else {
    throw new Error(`Unsupported season ${season} for OPM`)
  }
}

export function buildDefaultSegmentTimesFor(
  sportTypes: TSportType[],
  seasonType: TSeasonType,
  productYear: TSeasonYear,
  subsection?: SubsectionEnum,
) {
  const year = productYear
  const nflWeek1Day = year >= 2020 ? 8 : 4
  const isPostseason = seasonType === ENUM_POST
  const isNfl = sportTypes[0] === NflSportType
  // TODO qac: we need to hit primpy here or something... cant figure correct date for NFL week 1 without that
  // actually its just "use Labor Day + 3. Pretty sure its always the Thursday after Labor Day."

  // Date strings are set to 4 hours before actual time, e.g., if tournament locks at March 17 2022,12:15 pm we set it to 2022-03-17T08:15
  if (isPostseason && isNfl) {
    if (year >= 2020) {
      // NOTE LL: 2020 NFL Dates - Week 17 ends Jan 3 (Sunday)
      return {
        segmentStartsAt: `${year + 1}-01-04T08:00`,
        segmentEndsAt: `${year + 1}-02-15T08:00`,
        segmentPreOpenAt: `${year}-12-17T08:00`,
      }
    } else {
      return {
        segmentStartsAt: `${year + 1}-01-01T08:00`,
        segmentEndsAt: `${year + 1}-02-07T08:00`,
        segmentPreOpenAt: `${year}-12-19T08:00`,
      }
    }
  } else if (seasonType === ENUM_REGULAR && isNfl) {
    return {
      segmentStartsAt: `${year}-09-0${nflWeek1Day}T08:00`,
      segmentEndsAt: `${year + 1}-01-${year > 2020 ? "11" : "01"}T08:00`,
      segmentPreOpenAt: `${year}-06-06T08:00`,
    }
  } else if (seasonType === ENUM_PRE && isNfl) {
    return {
      segmentStartsAt: `${year}-08-06T08:00`,
      segmentEndsAt: `${year}-09-0${nflWeek1Day}T08:00`,
      segmentPreOpenAt: `${year}-06-02T08:00`,
    }
  } else if (isPostseason && sportTypes[0] === NcaafSportType) {
    return {
      segmentStartsAt: `${year}-12-16T08:00`,
      segmentEndsAt: `${year + 1}-02-25T08:00`,
      segmentPreOpenAt: `${year}-12-08T08:00`,
    }
  } else if (seasonType === ENUM_REGULAR && sportTypes[0] === NcaafSportType) {
    return {
      segmentStartsAt: `${year}-${year === 2020 ? `08-31` : `08-28`}T08:00`,
      segmentEndsAt: `${year}-12-${year === 2020 ? `07` : `06`}T08:00`,
      segmentPreOpenAt: `${year}-06-03T08:48`,
    }
  } else if (isPostseason && sportTypes[0] === NcaabSportType && subsection === SubsectionEnum.ConferenceTournament) {
    // NOTE qac: this is the product year, which is sportYear + 1!
    return {
      segmentStartsAt: `${year}-02-22T05:00`,
      segmentEndsAt: `${year}-05-25T08:00`,
      segmentPreOpenAt: `${year}-01-01T08:00`,
    }
  } else if (isPostseason && sportTypes[0] === NcaabSportType && subsection === SubsectionEnum.NcaaTournament) {
    // NOTE qac: this is the product year, which is sportYear + 1!
    // NOTE FOR SEGMENT LOCKS AT - this gets converted through toDbDate() and that adds 4 hours to this to try to make it UTC
    // but the DB reads it as local time, but will store it as UTC time, so it will add 4 more hours
    return {
      segmentStartsAt: `${year}-02-22T01:00`,
      segmentEndsAt: `${year}-06-01T08:00`,
      segmentLocksAt: `${year}-03-17T04:15`, // we lock at 12:15 for mens
      segmentPreOpenAt: `${year}-01-01T08:00`,
    }
  } else if (isPostseason && sportTypes[0] === NcaawSportType && subsection === SubsectionEnum.NcaawTournament) {
    // NOTE qac: this is the product year, which is sportYear + 1!
    // NOTE FOR SEGMENT LOCKS AT - this gets converted through toDbDate() and that adds 4 hours to this to try to make it UTC
    // but the DB reads it as local time, but will store it as UTC time, so it will add 4 more hours
    return {
      segmentStartsAt: `${year}-02-22T01:00`,
      segmentEndsAt: `${year}-06-02T08:00`,
      segmentLocksAt: `${year}-03-18T04:00`, // locks at 12:00pm
      segmentPreOpenAt: `${year}-01-02T08:00`,
    }
  } else if (isPostseason && sportTypes[0] === NcaabSportType && subsection === SubsectionEnum.NcaaTournamentSweetSixteen) {
    // NOTE qac: this is the product year, which is sportYear + 1!
    return {
      segmentStartsAt: `${year}-03-22T08:00`,
      segmentEndsAt: `${year}-06-22T08:00`,
      segmentLocksAt: `${year}-03-27T05:00`,
      segmentPreOpenAt: `${year}-03-01T08:00`,
    }
  } else {
    throw new Error(`unhandled period start`)
  }
}

export function isGIMapping(mapping: IRecordsMapping | ITournamentMapping): mapping is IRecordsMapping {
  return (mapping as IRecordsMapping).hasOwnProperty("GameInstance")
}
export function isTournamentMapping(mapping: IRecordsMapping | ITournamentMapping): mapping is ITournamentMapping {
  return (mapping as IRecordsMapping).hasOwnProperty("Tournament")
}
export const findOrBuildTournamentMappingFor = (tournamentId: number, mappings: IRecordsUnion) => {
  const tournament = Tournaments.find((t) => t._id === tournamentId)
  const mapping =
    (mappings.find((r) => isTournamentMapping(r) && r.Tournament.id === tournamentId) as ITournamentMapping) ||
    (tournament && ({ Tournament: { name: tournament.name, id: tournament._id } } as ITournamentMapping)) ||
    null
  if (!mapping) {
    throw new Error(`missing tournament for id ${tournamentId}`)
  }
  if (!mappings.includes(mapping)) {
    mappings.push(mapping)
  }
  // make sure ids match
  if (tournament) {
    mapping.Tournament.abbrev = "NCAA"
  }
  return mapping
}
export const buildKnownTournaments = (mappings: IRecordsUnion) => {
  for (const tourny of Tournaments) {
    findOrBuildTournamentMappingFor(tourny._id, mappings)
  }
}
export const buildMatchupsFor = (mappings: IRecordsUnion, addIds = false) => {
  const giMappings = mappings.filter((r) => isGIMapping(r)) as IRecordsMapping[]
  const tournamentMappings = mappings.filter((r) => isTournamentMapping(r)) as ITournamentMapping[]
  // attempt to create any matchups we know of
  const addedKeys = {} as any
  let currentId = 1
  for (const m of giMappings) {
    const Seasons = m.GameInstance.associations?.Season || emptyArray
    for (const Season of Seasons) {
      const Segments = Season.associations?.Segment || emptyArray
      for (const Segment of Segments) {
        const sportYear = getMatchupSportYearFor(Season)
        const Periods = Segment.associations?.Period || emptyArray
        for (const Period of Periods) {
          if (Period.tournamentId && !Season.hasGameDataOnly) {
            const { tournamentId } = Period
            const key = `${tournamentId}-${sportYear}`
            const val = addedKeys[key]
            if (!val || val === "redo") {
              if (!val) {
                addedKeys[key] = true
              }
              const tournament = Tournaments.find((t) => t._id === tournamentId)
              if (!tournament) {
                throw new Error(`missing tournament for id ${tournamentId}`)
              }
              const tournamentMapping =
                tournamentMappings.find((r) => r.Tournament.id === tournamentId) ||
                ({ Tournament: { name: tournament.name, id: tournament._id } } as ITournamentMapping)
              const existingMatchups = tournamentMapping.Tournament.associations?.Matchup || []
              if (NcaaTournament._id === tournamentId) {
                buildNcaaTournamentMatchups(Season.sportYear).forEach((m) => existingMatchups.push(m))
              } else if (NcaawTournament._id === tournamentId) {
                buildNcaaTournamentMatchups(Season.sportYear, true).forEach((m) => existingMatchups.push(m))
              } else {
                console.warn(`unhandled tournament '${tournamentMapping.Tournament.name}' for sportYear: '${sportYear}'`)
              }
              if (existingMatchups.length && tournamentMapping.Tournament.associations?.Matchup !== existingMatchups) {
                if (!tournamentMapping.Tournament.associations) {
                  tournamentMapping.Tournament.associations = { Matchup: existingMatchups }
                } else {
                  tournamentMapping.Tournament.associations.Matchup = existingMatchups
                }
              }
              if (addIds) {
                for (const matchup of existingMatchups) {
                  if (!matchup.id) {
                    matchup.id = currentId++
                    // If needed: we could go this route:
                    // if (settings.USE_PROD_SEEDING && matchup.id && matchup.id <= 275) {
                    //   const attrs = Object.keys(matchup)
                    //   for (const attr of attrs) {
                    //     if (attr !== "id") {
                    //       delete matchup[attr]
                    //     }
                    //   }
                    // }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return currentId
}

export const buildOPMPeriods = ({
  gameType,
  sportType,
  season,
  productYear,
  useProdSeeding,
}: {
  gameType: GameTypeEnum
  sportType: TSportType
  season: TSeasonType
  productYear: number
  useProdSeeding: boolean
}): any[] => {
  const { segmentStartsAt, segmentEndsAt } = buildOPMDefaultSegmentTimesFor(sportType, season, productYear)
  const segmentStartsAtDate = new Date(segmentStartsAt)
  const segmentEndsAtDate = new Date(segmentEndsAt)
  const isPostseason = season === "post"
  const periodLength = PeriodLengthEnum.WEEKLY
  const lengthOfPeriod = 7 // NFL and College are both 7 days
  const periods: unknown[] = []
  // NFL Playoffs for OPM Pickem + Survivor
  if (sportType === ENUM_NFL && isPostseason) {
    const numNFLRegularSeasonWeeks = 18
    NflPlayoffPeriodDescriptions.forEach((description, i) => {
      const weekNumber = i + numNFLRegularSeasonWeeks + 1
      const startsAt =
        periodStartsAtFor(
          { startsAt: segmentStartsAt, sportType: ENUM_NFL, weekNumber: weekNumber - numNFLRegularSeasonWeeks },
          periodLength,
        ).getTime() +
        lengthOfPeriod * i * OneDay
      const endsAt = i === 3 ? segmentEndsAtDate.getTime() : startsAt + lengthOfPeriod * OneDay
      periods.push({
        order: weekNumber,
        description,
        startsAt: toDbDate(new Date(startsAt), true),
        endsAt: toDbDate(new Date(endsAt), true),
      })
    })
  } else {
    const span = lengthOfPeriod * OneDay
    let weekNumber = 1
    const endsAt = segmentEndsAtDate.getTime()
    // let currAt = segmentStartsAtDate.getTime();
    let currAt = periodStartsAtFor({ startsAt: segmentStartsAtDate, sportType, weekNumber }, periodLength).getTime()
    // console.log(`running: ${segmentStartsAt} -> ${segmentEndsAt} (${span + currAt} < (${endsAt + span}))`);
    // console.log(currAt, endsAt, span);
    // NOTE qac: if the end time falls in the middle of a period, ignore that period
    while (span + currAt < endsAt) {
      // console.log(`i: ${weekNumber}: ${currAt}`);
      if (weekNumber > 20) {
        throw new Error(`nope`)
      }
      // NOTE qac: ncaaf week 15 doesnt exist... see https://www.cbssports.com/college-football/schedule/
      // We also dont include the week 16 since it only has army vs navy
      const isNCAAFRegular = !isPostseason && sportType === ENUM_NCAAF
      const ncaaFMultiple = [1].includes(weekNumber) && isNCAAFRegular ? 2 : 1
      if (ncaaFMultiple === 2 && weekNumber > 1) {
        weekNumber++
      }
      const newAt = periodStartsAtFor(
        {
          startsAt: span * ncaaFMultiple + currAt,
          sportType,
          weekNumber: weekNumber + 1,
        },
        periodLength,
      ).getTime()
      const periodStartsAt = new Date(currAt)
      const periodEndsAt = new Date(newAt)
      // NOTE qac: uuuugh for NCAAF week 1 there is a game on monday... so we bump the date up by 1 day...
      if (isNCAAFRegular && weekNumber === 1) {
        periodEndsAt.setHours(periodEndsAt.getHours() + 24)
      }
      if (isNCAAFRegular && weekNumber === 2) {
        periodStartsAt.setHours(periodStartsAt.getHours() + 24)
      }

      const description = `Week ${weekNumber}`

      periods.push({
        order: weekNumber,
        description,
        startsAt: toDbDate(periodStartsAt, true),
        endsAt: toDbDate(periodEndsAt, true),
      })
      currAt = newAt
      weekNumber++
    }
  }
  return periods
}

export const buildPeriods = (
  gameType: GameTypeEnum,
  sportTypes: TSportType[],
  periodLength: PeriodLengthEnumType,
  seasonType: TSeasonType,
  year: TSeasonYear,
  productYear: number,
  subsection?: SubsectionEnum,
  events = [] as any[],
  byWeek = [] as any[],
  hasGameDataOnly = false,
  isChallenge = false,
  useProdSeeding = false,
) => {
  const { segmentStartsAt, segmentEndsAt, segmentPreOpenAt, segmentLocksAt } = buildDefaultSegmentTimesFor(
    sportTypes,
    seasonType,
    productYear,
    subsection,
  )
  const periods = [] as any[]
  // const nowAt = new Date();
  const isPostseason = seasonType === ENUM_POST
  const isNcaab = sportTypes.includes(NcaabSportType)
  const isNcaaw = sportTypes.includes(NcaawSportType)
  const isNfl = sportTypes.includes(NflSportType)
  const isNcaaf = sportTypes.includes(NcaafSportType)
  const isNFLPlayoff = isNfl && isPostseason
  const segmentStartsAtDate = new Date(segmentStartsAt)
  const segmentEndsAtDate = new Date(segmentEndsAt)
  const sortedEvents = [...events].sort(sortByStartsAt) as any[]
  const isNcaabTournament =
    isNcaab && [SubsectionEnum.ConferenceTournament, SubsectionEnum.NcaaTournament].includes(subsection || SubsectionEnum.NONE)
  const sportYear = isNcaabTournament || isNcaaw ? year - 1 : year
  const sportType = sportTypes[0]

  const filteredByWeek = (byWeek || []).filter(
    (week) => sportTypes.includes(week.sportType) && week.year === sportYear && week.seasonType === seasonType,
  )
  const firstEventStartsAt = sortedEvents.length ? new Date(sortedEvents[0].startsAt) : null
  if (
    subsection === SubsectionEnum.NcaaTournament ||
    subsection === SubsectionEnum.NcaaTournamentSweetSixteen ||
    subsection === SubsectionEnum.NcaawTournament
  ) {
    const tournamentId = subsection === SubsectionEnum.NcaawTournament ? NcaawTournament._id : NcaaTournament._id
    // const Matchup = buildNcaaTournamentMatchups()
    periods.push({
      order: 1,
      description: "Full Tournament",
      startsAt: toDbDate(segmentStartsAt, true),
      locksAt: toDbDate(segmentLocksAt || firstEventStartsAt || segmentEndsAt, true),
      endsAt: toDbDate(segmentEndsAt, true),
      tournamentId,
      // associations: {
      //   Matchup,
      // },
    })
    if (!hasGameDataOnly) {
      const tournamentRounds = subsection === SubsectionEnum.NcaaTournament || SubsectionEnum.NcaawTournament ? [1, 2, 3, 4, 5, 6, 7] : [4, 5, 6, 7]
      for (const tournamentRound of tournamentRounds) {
        if (tournamentRound > 1) {
          const description =
            (tournamentRound === 2 && `First Round`) ||
            (tournamentRound === 3 && `Second Round`) ||
            (tournamentRound === 4 && `Sweet 16`) ||
            (tournamentRound === 5 && `Elite 8`) ||
            (tournamentRound === 6 && `Final Four`) ||
            `Championship`
          periods.push({
            order: tournamentRound,
            tournamentRound,
            tournamentId,
            description,
            startsAt: toDbDate(segmentStartsAt, true),
            endsAt: toDbDate(segmentEndsAt, true),
          })
        }
      }
    }
  } else if (periodLength === PeriodLengthEnum.FULL) {
    if (gameType === GameTypeEnum.PICKEM && isPostseason) {
      periods.push({
        order: 1,
        description: "Full Playoffs",
        startsAt: toDbDate(segmentStartsAt, true),
        locksAt: toDbDate(segmentLocksAt || firstEventStartsAt || segmentEndsAt, true),
        endsAt: toDbDate(segmentEndsAt, true),
      })
    } else {
      throw new Error(`need to implement`)
    }
  } else if (!!events && !!events.length) {
    buildPeriodsFor(sortedEvents, periods, periodLength)
  } else if (filteredByWeek.length) {
    buildPeriodsFor(filteredByWeek, periods, periodLength)
  } else if (gameType === ENUM_PICKEM && isNFLPlayoff) {
    NflPlayoffPeriodDescriptions.forEach((description, i) => {
      const weekNumber = i + 1
      const startsAt = periodStartsAtFor({ startsAt: segmentStartsAt, sportType: ENUM_NFL, weekNumber }, periodLength).getTime() + 7 * i * OneDay
      const endsAt = i === 3 ? segmentEndsAtDate.getTime() : startsAt + 7 * OneDay
      periods.push({
        order: weekNumber,
        description,
        startsAt: toDbDate(new Date(startsAt), true),
        endsAt: toDbDate(new Date(endsAt), true),
      })
    })
  } else {
    // NOTE qac: only support a week for now
    const span = 7 * OneDay
    let weekNumber = 1
    const endsAt = segmentEndsAtDate.getTime()
    // let currAt = segmentStartsAtDate.getTime();
    let currAt = periodStartsAtFor({ startsAt: segmentStartsAtDate, sportType, weekNumber }, periodLength).getTime()
    // console.log(`running: ${segmentStartsAt} -> ${segmentEndsAt} (${span + currAt} < (${endsAt + span}))`);
    // console.log(currAt, endsAt, span);
    // NOTE qac: if the end time falls in the middle of a period, ignore that period
    while (span + currAt < endsAt) {
      // console.log(`i: ${weekNumber}: ${currAt}`);
      if (weekNumber > 20) {
        throw new Error(`nope`)
      }
      // NOTE qac: ncaaf week 15 doesnt exist... see https://www.cbssports.com/college-football/schedule/
      // We also dont include the week 16 since it only has army vs navy
      const isNCAAFRegular = !isPostseason && sportTypes.join("-") === ENUM_NCAAF
      const ncaaFMultiple = [1].includes(weekNumber) && isNCAAFRegular ? 2 : 1
      if (ncaaFMultiple === 2 && weekNumber > 1) {
        weekNumber++
      }
      const newAt = periodStartsAtFor(
        {
          startsAt: span * ncaaFMultiple + currAt,
          sportType,
          weekNumber: weekNumber + 1,
        },
        periodLength,
      ).getTime()
      const periodStartsAt = new Date(currAt)
      const periodEndsAt = new Date(newAt)
      // NOTE qac: uuuugh for NCAAF week 1 there is a game on monday... so we bump the date up by 1 day...
      if (isNCAAFRegular && weekNumber === 1) {
        periodEndsAt.setHours(periodEndsAt.getHours() + 24)
      }
      if (isNCAAFRegular && weekNumber === 2) {
        periodStartsAt.setHours(periodStartsAt.getHours() + 24)
      }
      // NOTE qac: we skip HOF game (week 1) for preseason nfl
      const isNFLPreseasonWeek1 = seasonType === ENUM_PRE && isNfl && weekNumber === 1
      // NOTE qac: 2020 NCAAF #COVID19 - we pushed challenge back to first week with SEC games: sept 26
      const isSkipped2020NCAAF =
        sportType === ENUM_NCAAF && year === 2020 && seasonType === ENUM_REGULAR && isChallenge && periodEndsAt.getTime() < Date.parse("sept 26 2020")

      if (!isNFLPreseasonWeek1 && !isSkipped2020NCAAF) {
        periods.push({
          order: weekNumber,
          description: `Week ${weekNumber}`,
          startsAt: toDbDate(periodStartsAt, true),
          endsAt: toDbDate(periodEndsAt, true),
        })
      }
      currAt = newAt
      weekNumber++
    }
  }
  if (segmentPreOpenAt && periods.length) {
    // NOTE qac: for pre-opening games, adjust the FIRST period startsAt UNLESS:
    // its a bracket (FULL), in which case ONLY adjust if the period is NOT a period modeling round scoring (if we go that route)
    if (periodLength === PeriodLengthEnum.FULL) {
      for (const period of periods) {
        if (!period.tournamentRound) {
          addExtraToPeriod(period, {
            [periodOverrideStartsAtAttr]: Date.parse(segmentPreOpenAt),
          })
        }
      }
    } else {
      const extra = {
        [periodOverrideStartsAtAttr]: Date.parse(segmentPreOpenAt),
      }
      addExtraToPeriod(periods[0], extra)

      const skipNcaafLastWeek = isNcaaf && year > 2020 && seasonType === ENUM_REGULAR
      if (skipNcaafLastWeek) {
        addExtraToPeriod(periods[periods.length - 1], {
          skipPeriod: true,
          skipPeriodText: `This season is over.  Thanks for playing!`,
        })
      }
    }
  }
  if (year === 2020 && gameType === ENUM_PARLAY && seasonType === ENUM_REGULAR) {
    // 2020 parlay: we had favored team spread props for week 1 #neverforget
    const weekOnePeriod = periods.find((p) => p.order === 1)
    if (weekOnePeriod) {
      const extra = getExtraColumn(weekOnePeriod)
      extra.spreadPushDecider = ENUM_UNDERDOG
      weekOnePeriod.extra = extra
    }
  }
  // process disabledEventTitles:
  if (sportTypes.join("-") === GameSportType.values.NCAAF.value && gameType === GameTypeEnum.PICKEM && seasonType === ENUM_POST) {
    for (const period of periods) {
      addExtraToPeriod(period, {
        [periodExtraDisabledEventTitlesKey]: [championshipGameName(GameSportType.values.NCAAF.value)],
        [periodExtraTiebreakerQuestionTitleKey]: "Tiebreaker: CFB National Championship Game",
      })
      period.description = "Bowl Games"
    }
  }
  // TODO qac: process isCurrent and isPickable IF it makes sense
  // we dont want to set isCurrent or isPickable since it would overwrite on prod, leave it as the default, and let primpy update it
  // isCurrent: periodStartsAt < nowAt && periodEndsAt > nowAt,
  // isPickable: false,
  // isCurrent: false,
  // isPickable: events.length > 0,
  return periods
}

function addExtraToPeriod(period: any, extra: any) {
  const orig = getExtraColumn(period)
  period.extra = Object.assign(orig, extra)
}

export function buildPeriodsFor(eventsOrByWeek: any[], periods = [] as any[], periodLength = PeriodLengthEnum.WEEKLY as PeriodLengthEnumType) {
  const isActualEvents = eventsOrByWeek.length && eventsOrByWeek[0].hasOwnProperty("startsAt")
  if (!isActualEvents) {
    eventsOrByWeek.forEach((week, i) => {
      // console.dir(week)
      const sportType = week.sportType
      const weekNumber = week.weekNumber
      const prevPeriod = periods.length ? periods[i - 1] : { endsAt: toDbDate(new Date()) }
      const startsAt = !prevPeriod.endsAt
        ? periodStartsAtFor({ startsAt: week["MIN(startsAt)"], sportType, weekNumber }, periodLength)
        : new Date(prevPeriod.endsAt)
      const endsAt = new Date(periodStartsAtFor({ startsAt: week["MAX(startsAt)"], sportType, weekNumber }, periodLength).getTime() + 7 * OneDay)
      periods.push({
        order: weekNumber,
        description: `Week ${weekNumber}`,
        isCurrent: false,
        isPickable: false,
        startsAt: toDbDate(startsAt, true),
        endsAt: toDbDate(endsAt, true),
      })
    })
  } else {
    eventsOrByWeek.sort(sortByStartsAt)
    const weekNumbers = unique(eventsOrByWeek.map((event) => event.weekNumber))
    weekNumbers.forEach((weekNumber, i) => {
      const periodEvents = eventsOrByWeek.filter((evt) => evt.weekNumber === weekNumber)
      // console.dir(periodEvents)
      const prevPeriod = periods.length ? periods[i - 1] : { endsAt: toDbDate(new Date(), true) }
      const startsAt = periodEvents.length ? periodStartsAtFor(periodEvents[0], periodLength) : new Date(prevPeriod.endsAt)
      const endsAt = new Date(
        (periodEvents.length ? periodStartsAtFor(periodEvents[periodEvents.length - 1], periodLength) : startsAt).getTime() + 7 * OneDay,
      )
      periods.push({
        order: weekNumber,
        description: `Week ${weekNumber}`,
        isCurrent: false,
        isPickable: false,
        startsAt: toDbDate(startsAt, true),
        endsAt: toDbDate(endsAt, true),
      })
    })
  }
  // const startsAtAttr = isActualEvents ? 'startsAt' : 'MIN(startsAt)'
  return periods
}
interface IStartsAtForParam {
  startsAt: any
  sportType: any
  weekNumber?: any
}

export const periodStartsAtFor = (param: IStartsAtForParam, periodLength?: PeriodLengthEnumType) => {
  const { startsAt, sportType, weekNumber } = param
  // We need to use UTC here and convert to correct local time using toDBDate
  let perStartsAt = new Date(startsAt)
  // console.log(`perStartsAt (${sportType}) 1: ${JSON.stringify(perStartsAt)} - ${weekNumber}, ${startsAt}, ${sportType}`)
  // set to 4am ET
  const rolloverHour = 10
  let desiredPeriodStartDay = 1 // 1 == monday
  if (sportType === ENUM_NCAAF) {
    // NOTE qac: week 1 is the only week with a monday game (always?)
    // otherwise there are a few tuesday games so its nice to rollover monday
    desiredPeriodStartDay = weekNumber === 1 ? 2 : 1
  }
  // NOTE qac: NFL pre / reg season is tuesday, but POST is monday!
  if (sportType === ENUM_NFL && periodLength === PeriodLengthEnum.WEEKLY) {
    desiredPeriodStartDay = 2
    // historically we have cared more for these, so lets keep the rollover at 11 AM for pre / reg NFL
    // rolloverHour = 11
  }
  perStartsAt.setUTCHours(rolloverHour, 0, 0, 0)
  while (perStartsAt.getUTCDay() < desiredPeriodStartDay) {
    // console.log(`minus 1 day (${perStartsAt.getUTCDay()}, ${desiredPeriodStartDay})...`)
    perStartsAt = new Date(perStartsAt.getTime() - OneDay)
  }
  // console.log(`perStartsAt 2: ${JSON.stringify(perStartsAt)}`)
  while (perStartsAt.getUTCDay() > desiredPeriodStartDay) {
    // console.log(`minus 1 day (${perStartsAt.getUTCDay()}, ${desiredPeriodStartDay})...`)
    perStartsAt = new Date(perStartsAt.getTime() - OneDay)
  }
  return perStartsAt
}
