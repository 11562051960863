import { DbRow } from "./db-typings"
import { dateToEpoch } from "./misc-utils"

export const periodExtraDisabledEventTitlesKey = "disabledEventTitles"
export const periodExtraTournamentWinnerPlayInTeamIdsKey = "tournamentWinnerPlayInTeamIds"
export const periodExtraTiebreakerQuestionTitleKey = "tiebreakerQuestionTitle"
export const periodNotificationBannerHtmlKey = "notificationBannerHtml"
export const periodNotificationBannerBackgroundColorKey = "notificationBannerBackgroundColor"
export const periodNotificationBannerColorKey = "notificationBannerColor"
export const periodGlobalNotificationHtmlKey = "globalNotificationHtml"
export const periodGlobalNotificationBackgroundColorKey = "globalNotificationBackgroundColor"
export const periodGlobalNotificationColorKey = "globalNotificationColor"
export const periodOverrideStartsAtAttr = "overrideStartsAt"
export const lastDismissedReactivateSeasonIdKey = "lastDismissedReactivateSeasonId"
export const tournamentPickPercentKey = "tournamentPickPercent"

export const dupMatchupHackKey = "isDupMatchup"

export type IEventGameOdds = {
  source: string
  bookId: string
  type: string
  side: string
  outcomeOdds: string
  openingOutcomeOdds: string
  bookName: string
  bookDisplayName: string
  teamId?: number
  value?: string
  openingValue?: string
  selectionId?: string
}

interface IEventOddsMarketShared {
  id: string
  odds: string
  openingOdds: string
  oddsTrend?: "NEUTRAL" | "UP" | "DOWN"
  teamId?: number | "DRAW"
  choice?: "OVER" | "UNDER"

  selectionId?: string
}

interface IEventOddsMarketBook {
  id: string
  name: string
  displayName: string
}

export interface IEventOddsMarketSpread extends IEventOddsMarketShared {
  teamId: number
  spread: string
  openingSpread: string
  side: string
}

export interface IEventOddsMarket2WayOr3Way extends IEventOddsMarketShared {
  teamId: number | "DRAW"
  isDrawVersion: boolean
}

export interface IEventOddsMarketTotal extends IEventOddsMarketShared {
  choice: "OVER" | "UNDER"
  total: string
  openingTotal: string
}

export interface IEventOddsMarket {
  id: string
  bookUsed: IEventOddsMarketBook
  spreads: IEventOddsMarketSpread[]
  moneyLines: IEventOddsMarket2WayOr3Way[]
  totals: IEventOddsMarketTotal[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getExtraColumn = <T = any>(row: any, columnName = "extra", fallback = {} as T): T => {
  // NOTE qac: account for knex not inflating this
  const extra = row[columnName] || fallback
  if (typeof extra === "string") {
    return JSON.parse(row[columnName])
  }
  return extra
}
export const serializeExtraColumn = (extra: any) => {
  if (Object.keys(extra || {}).length) {
    return JSON.stringify(extra)
  } else {
    return null
  }
}

export function getStartsAtForPeriod(per: DbRow): number {
  // NOTE qac: this is for convincing a season to rollover before it's time...
  return getExtraColumn(per)[periodOverrideStartsAtAttr] || dateToEpoch(per.startsAt)
}
